import React from "react";
import Layout from "../components/layout";
import AddressAndTransport from "../components/AddressAndTransport";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import ContactForm from "../components/contactForm";
import { graphql, useStaticQuery } from "gatsby";
import { parseNestedHTML } from "../Utils";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";
import NinjaStarSVG from "../components/svg/NinjaStarSVG";
import SafeHtmlParser from "../components/safe-html-parser";
const settings = {
	dots: true,
	dotsClass: "slick-dots slick-thumb",
	infinite: true,
	speed: 1000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	autoplay: true,
};

const SeoPageTemplate = ({ data: { wpSeoPage, site } }) => {
	const SeoPage = wpSeoPage.sEOPageFields;
	const seoFields = wpSeoPage.seoFields;
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${site.siteMetadata.siteUrl}`,
					id: `${site.siteMetadata.siteUrl}`,
				},
			},

			{
				"@type": "ListItem",
				position: "2",
				name: `${wpSeoPage?.title}`,
				item: {
					url: `${site.siteMetadata.siteUrl}/${wpSeoPage?.slug}`,
					id: `${site.siteMetadata.siteUrl}/${wpSeoPage?.slug}`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${wpSeoPage?.slug}`,
					title: seoFields?.opengraphTitle,
					description: seoFields?.opengraphDescription,
					images: [
						{
							url: `${seoFields?.image?.node?.localFile.publicURL}`,
							width: `${seoFields?.image?.node?.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node?.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node?.altText}`,
						},
					],
				}}
			/>
			<section className="position-relative ">
				<div className="position-relative hero-carousal">
					<div
						style={{
							zIndex: 1,
							background:
								"linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%)",
							borderTop: "1px solid #FFF",
							borderBottom: "1px solid #FFF",
						}}
						className="position-absolute h-100 w-100"
					>
						<Container className="h-100">
							<Row className="align-items-center h-100">
								<Col>
									<div className="h-100 ">
										<div className="stylishText text-uppercase  ">
											<SafeHtmlParser htmlContent={SeoPage.heroSection.title} />
										</div>
										<Button
											href={SeoPage.heroSection.buttonLink}
											className="text-white text-uppercase w-100 w-md-auto  fs-5 px-4 py-2"
										>
											{SeoPage.heroSection.btnText}
										</Button>
									</div>
								</Col>
							</Row>
						</Container>
					</div>
					<div className="slider-container">
						<Slider {...settings}>
							{SeoPage.heroSection.slider.map((slider, i) => (
								<div key={i}>
									<GatsbyImage
										className="images-height"
										image={
											slider.image?.node.localFile?.childImageSharp
												?.gatsbyImageData
										}
										style={{
											objectFit: "cover",
											width: "100%",
										}}
										alt={slider.image?.node?.altText}
									/>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</section>
			<section className="py-5 pt-lg-7">
				<Container>
					<Row>
						<Col>
							<h2 className="display-5 pb-3 text-uppercase">
								{SeoPage.venueForCelebration.title}
							</h2>

							<div>
								<SafeHtmlParser
									htmlContent={SeoPage.venueForCelebration.body}
								/>
							</div>
							{SeoPage.venueForCelebration.buttonLink?.title.length > 0 && (
								<Button
									href={SeoPage.venueForCelebration.buttonLink.url}
									className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
								>
									{SeoPage.venueForCelebration.buttonLink.title}
								</Button>
							)}

							<div className="w-100 py-5">
								<GatsbyImage
									image={
										SeoPage.supervision.image?.node.localFile?.childImageSharp
											?.gatsbyImageData
									}
									style={{
										objectFit: "cover",
										width: "100%",
									}}
									alt={SeoPage.supervision.image?.node?.altText}
								/>
							</div>
							<h2 className="display-5 pb-3 text-uppercase">
								{SeoPage.supervision.title}
							</h2>

							<div>
								<SafeHtmlParser htmlContent={SeoPage.supervision.body} />
							</div>
							{SeoPage.supervision.buttonLink?.title.length > 0 && (
								<Button
									href={SeoPage.supervision.buttonLink.url}
									className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
								>
									{SeoPage.supervision.buttonLink.title}
								</Button>
							)}
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pb-5">
				<Container>
					<Row className="align-items-center gy-5">
						<Col lg={{ span: 6, order: "last" }}>
							<div className="slider-container">
								<Slider {...settings}>
									{SeoPage.whyVisitNinjaArena.slider.map((slider, i) => (
										<div key={i}>
											<GatsbyImage
												image={
													slider.image?.node.localFile?.childImageSharp
														?.gatsbyImageData
												}
												alt={slider.image?.node?.altText}
											/>
										</div>
									))}
								</Slider>
							</div>
						</Col>
						<Col lg={6}>
							<h2 className="display-5 pb-3 text-uppercase">
								{SeoPage.whyVisitNinjaArena.title}
							</h2>
							<div>
								<SafeHtmlParser
									htmlContent={SeoPage.whyVisitNinjaArena.resons}
								/>
							</div>
							<Button
								href={SeoPage.whyVisitNinjaArena.buttonLink}
								className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
							>
								{SeoPage.whyVisitNinjaArena.btnText}
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5 py-lg-7">
				<Row className="align-items-center gx-0">
					<Col lg={6}>
						<div
							style={{
								position: "relative",
								display: "inline-block", // Or 'block' depending on your layout
							}}
						>
							<div
								style={{
									position: "absolute",
									top: 0,
									right: 0,
									bottom: 0,
									left: 0,
									background:
										"linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)",
									pointerEvents: "none", // Allows interaction with the GatsbyImage
									zIndex: 1, // Ensures the overlay is above the image
								}}
							></div>
							<GatsbyImage
								image={
									SeoPage.privateRoom.image?.node.localFile?.childImageSharp
										?.gatsbyImageData
								}
								className="w-100"
								style={{
									objectFit: "cover",
									width: "100%",
								}}
								alt={SeoPage.privateRoom.image?.node?.altText}
							/>
						</div>
					</Col>
					<Col className="px-lg-8 p-4 py-lg-0" lg={6}>
						<h3 className="display-5  text-uppercase text-white ">
							{SeoPage.privateRoom.title}
						</h3>
						<div className="">
							<SafeHtmlParser htmlContent={SeoPage.privateRoom.body} />
						</div>
						<p className="text-white">{SeoPage.privateRoom.subheading}</p>
						<Button
							href={SeoPage.privateRoom.buttonLink}
							className="text-white text-uppercase w-100 w-md-auto fs-5 px-4 py-2"
						>
							{SeoPage.privateRoom.btnText}
						</Button>
					</Col>
				</Row>
			</section>

			<section className="pb-5 pb-lg-7">
				<Container>
					<Row className="justify-content-center">
						<Col lg={8}>
							<Stack gap={3} className="align-items-center text-center">
								<h2 className="display-5 pb-0 text-uppercase">
									{SeoPage.customizedPackage.title}
								</h2>

								<div>
									<SafeHtmlParser
										htmlContent={SeoPage.customizedPackage.body}
									/>
								</div>
								<p className="text-white">
									{SeoPage.customizedPackage.subheading}
								</p>
								<Button
									href={SeoPage.customizedPackage.buttonLink}
									className="text-white text-uppercase w-100 w-md-auto mt-2 fs-5 px-4 py-2"
								>
									{SeoPage.customizedPackage.btnText}
								</Button>
							</Stack>
						</Col>
					</Row>
				</Container>
			</section>

			<section>
				<AddressAndTransport
					address={SeoPage.address}
					vehiclesStatus={SeoPage.vehicleStatus}
				/>
			</section>
			<section id="form" className="py-5 py-lg-7">
				<Container>
					<Row>
						<Col>
							<h2 className="display-5 pb-3 text-uppercase text-center">
								Get in Touch
							</h2>

							<ContactForm />
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default SeoPageTemplate;

export const pageQuery = graphql`
	query SeoPageById($id: String!) {
		wpSeoPage(id: { eq: $id }) {
			sEOPageFields {
				address {
					btnText
					buttonLink
					title
					location {
						lat
						lng
					}
				}
				customizedPackage {
					body
					btnText
					buttonLink
					subheading
					title
				}
				heroSection {
					btnText
					buttonLink
					slider {
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					title
				}
				privateRoom {
					body
					btnText
					buttonLink
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
				}
				supervision {
					body
					image {
						node {
							altText
							localFile {
								publicURL
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
					title
					buttonLink {
						url
						title
					}
				}
				vehicleStatus {
					body
					title
					icon {
						node {
							sourceUrl
							altText
						}
					}
				}
				venueForCelebration {
					body
					title
					buttonLink {
						url
						title
					}
				}
				whyVisitNinjaArena {
					btnText
					buttonLink
					title
					slider {
						image {
							node {
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
					}
					resons
				}
			}
			seoFields {
				localBusinessSchema
				metaDescription
				opengraphDescription
				opengraphTitle
				title
				image {
					node {
						altText
						localFile {
							publicURL
							childImageSharp {
								original {
									height
									width
								}
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
							}
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
